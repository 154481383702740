import React from 'react'
import './HealthWorkersSection.css'
function HealthWorkersSection() {
  return (
    <div className='HealthWorkersSection'>
      <div className="group-image">

      </div>
      <div className="info">
       <div className="overlay">
        <div className="items">
            <h1 className='text-3xl font-bold py-4' style={{color: 'var(--primaryColor)'}}>Health Workers For NDC</h1>
        <p>We the members  of the <strong>National Democratic Congress (NDC) <span>Believing in the destiny</span></strong> of Ghanaians to build society which realizes truly human values based on the sustainable development of the resources with which nature has generously endowed us;</p>
        <p>Health workers for <strong>JM, 2024</strong> are determined to assist and to  provide all health related framework of voluntary and benevolent polical association within which Ghanaians from all walks of life and from different traditions can surmount their differences and contribute democratically and passionately to the rescuing Ghana.</p>
        </div>
       </div>
      </div>
      <div className="ImageSection" style={{background: "url(images/dev/Dramani_Mahama.jpg) no-repeat center center/cover"}}>

      </div>
    </div>
  )
}

export default HealthWorkersSection
