import React from 'react'
import { NavLink } from 'react-router-dom'
// import { openTestimonyModal } from '../../services/features/NavigatorSlide'
import './Footer.css'
import { FaFacebook, FaTelegram, FaTwitter } from 'react-icons/fa'

function Footer() {
   
  return (
      <>
    <div className='Footer'>
    <div className='overlay'>
    <div className='container'>
       <div className="sec1">
      <div className="">
         
         <span>HW4JM</span>
      </div>
        <div className='searchJoinBtn flex'>
            <div className="searchBtn"><NavLink to={'/search'}>Search</NavLink></div>
            <div className="joinUsBtn"><NavLink to={'/register'}>Join Us</NavLink></div>
        </div>
       </div>
       <div className="sec2">
        <div className="item1">
            <h4 className='title'>Quick Links</h4>
            <ul>
                <li><NavLink to={'/'}>Home</NavLink></li>
                <li><NavLink to={'/contact'}>Contact us</NavLink></li>
                <li><NavLink to={'/register'}>Join us</NavLink></li>
                <li><NavLink to={'/about'}>About us</NavLink></li>
                <li><NavLink to={'/services'}>Our Services</NavLink></li>
                {/* <li onClick={() => dispatch(openTestimonyModal(true))}><NavLink to={'#'}>Share Testimony</NavLink></li> */}
            </ul>
        </div>
        
        <div className="item1">
            <h4 className='title'>Help</h4>
            <ul>
                <li><NavLink to={'/faq'}>FAQs</NavLink></li>
                <li><NavLink className='/support'>Support</NavLink></li>
                <li><NavLink to={'https://ndcgh.org/'} target='_blank'>NDC Main Website</NavLink></li>
                <li><NavLink to={'/terms'}>Terms and Conditions</NavLink></li>
                {/* <li><a href={'https://www.upnmgh.com'} target="_blank" rel='noreferrer'>UPNMG Portal</a></li> */}
                
            </ul>
        </div>
        <div className="item1">
            <h4 className='title'>Management</h4>
            <ul>
                <li><NavLink to={'/team'}>Our Team </NavLink></li>
                <li><NavLink to={'/blogs'}>Visit Our Blog</NavLink></li>
                {/* <li><NavLink>Join us</NavLink></li> */}
                <li><NavLink to={'/register'}>Want to join us?</NavLink></li>
                {/* <li><NavLink to={'/reason-to-become-a-member'}>Reasons to join us</NavLink></li> */}
            </ul>
        </div>
        <div className="socialMedia">
            <h4 className='title'>Find Us</h4>
            <div className="socialIcons">

            </div>
            <p>Find us on our social media plaforms for more feeds</p>
            <div className="value socialMediaIcons flex gap-4 my-4">
                <NavLink  to={'https://www.facebook.com/HW4JM'} target='_blank'>
                  <FaFacebook className='' size={'35px'} color='rgb(0, 81, 255)' />
                </NavLink>
                <NavLink to={'https://twitter.com/HW4JM'} target='_blank'>
                  <FaTwitter size={'35px'} color='rgb(0, 186, 219)' />
                </NavLink>
                <NavLink  to={'https://t.me/HW4JM'} target='_blank'>
                  <FaTelegram  size={'35px'} color='rgb(3, 87, 121)' />
                </NavLink>
              </div>
        </div>
       </div>

       <div className="terms">
           <li><NavLink to={'/terms'}>Terms & conditions</NavLink></li>
           <li><NavLink to={'/privacy-policy'}>Privacy policy</NavLink></li>
           <li><NavLink to={'/cookies'}>Cookies policies</NavLink></li>
           <li><NavLink to={'/registernd'}>How to join us</NavLink></li>
       </div>
       <hr />
       <div className="copyrightSection">
          <p> Copyright &copy; 2022 HealthWorkersForNDC. All rights reserved.</p>
       </div>
      </div>
       </div>
    </div>
 
    </>
  )
}

export default Footer