import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './Navbar.css'
function Navbar() {
  const navigate = useNavigate()
  return (
    <>
    
    <nav className='Navbar'>
      <div className="sec1 cursor-pointer" onClick={() => navigate('/') }>
      <img src="/images/dev/ndc-healthworker-logo.png" className="h-14 mr-3" alt=""/>
      <div className="text-logo">
       <div className="text1"> health Workers for </div>
       <div className="text2">NDC</div>
      </div>
      </div>
      <div className="sec2">
      <ul className="">
      <li>
        <NavLink to={'/'} className="" aria-current="page">Home</NavLink>
      </li>
      <li>
        <NavLink to={'/about'} className="">About</NavLink>
      </li>
      <li>
        <NavLink to={'/services'} className="">Services</NavLink>
      </li>
      <li>
        <NavLink to={'/contact'} className="">Contact</NavLink>
      </li>
    </ul>
      </div>
      <div className="sec3">
      <NavLink to={'/register'} type="button" className="btn">Join Us</NavLink>
      </div>
    </nav>
      
{/* <nav className="bg-red dark:bg-gray-900 relative w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
  <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
  <NavLink to="/" className="flex items-center">
      <img src="/images/dev/ndc-healthworker-logo.png" className="h-10 mr-3" alt=""/>
      <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">NDC HW</span>
  </NavLink>

  <div className="flex md:order-2">
      <NavLink to={'/register'} type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Join Us</NavLink>
      <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
      </button>
  </div>
  <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
      <li>
        <NavLink to={'/'} className="block py-2 pl-3 pr-4 text-white bg-red-700 rounded md:bg-transparent md:text-red-700 md:p-0 md:dark:text-red-500" aria-current="page">Home</NavLink>
      </li>
      <li>
        <NavLink to={'/about'} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</NavLink>
      </li>
      <li>
        <NavLink to={'/services'} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</NavLink>
      </li>
      <li>
        <NavLink to={'/contact'} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-red-700 md:p-0 md:dark:hover:text-red-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</NavLink>
      </li>
    </ul>
  </div>
  </div>
</nav> */}
  
    </>
  )
}

export default Navbar