import React from 'react'
import { useNavigate } from 'react-router-dom'
import './serviceDetailed.css'

function HealthEducation() {
    const navigate = useNavigate()
  return (
    <div className='HealthEducation'>
          <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
        <div className="overlay">
          <h1 className="text-white font-bold md:text-3xl sm:text-sm">Health Education</h1>
        </div>
      </div>
         
        <div className="serviceBody">
            <div className="overlay">
                <div className="container">
              <p>Another service group shall be rendering to prospective electorates is health education. Group will take it upon themselves to educate individuals on how to care for themselves after treatment to prevent relapse of condition and to maintain health during screening.</p>
              <p>Counseling services will also be part of the education package that will be provided by group relative to health of people and societies. </p>
                
                <div className="backBtn" onClick={() =>navigate('/services')}>Go Back</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HealthEducation
