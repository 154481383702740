import React from 'react'
import { useNavigate } from 'react-router-dom'
import './serviceDetailed.css'

function HealthScreening() {
    const navigate = useNavigate()
  return (
    <div className='HealthScreening'>
          <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
        <div className="overlay">
          <h1 className="text-white font-bold md:text-3xl sm:text-sm">Health screening</h1>
        </div>
      </div>
         
        <div className="serviceBody">
            <div className="overlay">
                <div className="container">
                <p>Health screenings shall be carried out by group in a strategic and timely manner aimed at consolidating our numbers among party members and making meaningful gains in popular votes. With the screenings, parameters that shall be checked among others include; Blood pressure Monitoring, Random and Fasting Blood Sugar, BMI check for body fat, general body checks, RDT for Malaria. Special checks as Ear, Nose and Throat shall be examined, Eyes checkup and any other check deemed necessary. </p>
                <p>With a multidisciplinary team (Doctors, Nurses, Laboratory technicians, Pharmacists, Nutritionists, etc) on standby, people who can be treated at the screening point will be given the desired treatment with either Drug (medications) or otherwise needed by individuals screened. Other complicated cases can be referred for further management at facilities where our members can be of help in providing discounted treatment to such individuals.</p>
                <p>. Services will be rendered freely at the screening point using resources galvanized and pooled by group members aimed at absorbing cost of treatment in the screening centers</p>
                <div className="backBtn" onClick={() =>navigate('/services')}>Go Back</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HealthScreening
