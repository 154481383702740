import React from 'react';
import { Carousel } from 'react-responsive-carousel'
import './Carousel.css'
const CarouselComponet = () => {

    return (

        <div className="Carousel">
            <Carousel className='' autoPlay interval={5000} showThumbs={false} infiniteLoop>
                <div>
                    <img src="/images/downloads/members1.jpg" alt="Image 1" />
                    {/* <img src="/images/dev/image1.png" alt="Image 1" /> */}
                    {/* <p className="legend">Text 1</p> */}
                </div>
                <div>
                    <img src="/images/dev/image5.jpg" alt="Image 2" />
                    {/* <p className="legend">Text 2</p> */}
                </div>
                <div>
                <img src="/images/downloads/members2.jpg" alt="Image 2" />

                    {/* <img src="images/dev/image3.png" alt="Image 3" /> */}
                    {/* <p className="legend">Text 3</p> */}
                </div>
            </Carousel>
            <div className="ImageSection" style={{background: 'url(images/dev/Dramani_Mahama.jpg)  no-repeat center center/cover'}}>
            {/* <img src="images/dev/John-Mahama-ndc1.jpeg" alt="Image 3" /> */}
            </div>
        </div>

    );
};

export default CarouselComponet;
