import React from 'react'
import './Gallery.css'
function Gallery() {
    return (
        <div className='Gallery'>
            <h1 className='text-2xl  lg:text-3xl font-bold text-center py-4'><span className='text-black'>N</span><span style={{ color: 'var(--primaryColor)' }}>D</span><span style={{ color: 'var(--secondaryColor)' }}>C</span> Health Workers <span>Gallery</span></h1>
            <div className="galleryContainer">
                {/* <img src="/images/dev/image1.png" alt="Image 1" /> */}
                <img src="/images/downloads/members2.jpg" alt="Image 1" />
                {/* <img src="/images/dev/image2.jpg" alt="Image 2" /> */}
                <img src="/images/downloads/members3.jpg" alt="Image 2" />
                {/* <img src="images/dev/image3.png" alt="Image 3" /> */}
                <img src="/images/downloads/members4.jpg" alt="Image 3" />
                {/* <img src="/images/dev/image4.jpg" alt="Image 2" /> */}
                <img src="/images/downloads/members1.jpg" alt="Image 2" />
                {/* <img src="images/dev/upnmg-nurse-com-group.jpeg" alt="Image 3" /> */}

                <img src="/images/downloads/members6.jpg" alt="Image 2" />
                
                <img src="/images/downloads/members7.jpg" alt="Image 3" />


            </div>
                {/* <div className="img2">
                    <img src="/images/downloads/members6.jpg" alt="Image 2" />
                    <img src="/images/downloads/members7.jpg" alt="Image 3" />

                </div> */}
        </div>
    )
}

export default Gallery
