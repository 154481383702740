// 
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './serviceDetailed.css'

function DonationOfMedicalEquip() {
    const navigate = useNavigate()
  return (
    <div className='DonationOfMedicalEquip'>
          <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
        <div className="overlay">
          <h1 className="text-white font-bold md:text-3xl sm:text-sm">Donation of medical consumables and equipment</h1>
        </div>
      </div>
         
        <div className="serviceBody">
            <div className="overlay">
                <div className="container">
            <p>We also engage in occasional and need based strategic donation of medical consumables and equipment to facilities in need. This will be done by group’s efforts of pooling these consumables and equipment from within and carrying out the donation in the name of the party. </p> <p>Donations will be done in such a way to provide good name for the party, a good marketing strategy the party can later stand on firmly to execute political campaigns.</p>
                <div className="backBtn" onClick={() =>navigate('/services')}>Go Back</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DonationOfMedicalEquip
