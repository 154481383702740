import React from 'react'
import {FaAlignLeft} from 'react-icons/fa'
import './Navbar.css'
import { NavLink, useNavigate } from 'react-router-dom'
import $ from 'jquery';
function MobileNavbar() {
  const navigate = useNavigate()
    const closeMenu = () => {
       $('.MobileNavbarDropdown').fadeOut()
    }
  return (
    <div className='mobileNavigationBar'>
    <nav className='MobileNavbar'>
     <div className="imgCover flex items-center font-bold" onClick={() =>navigate('/')}>
     <img src="/images/dev/ndc-healthworker-logo.png" className="h-12 mr-3" alt=""/>
     <div className="text1" style={{color: 'var(--secondaryColor)', position: 'relative', left: '-20px', fontSize: '1.4rem'}}>HW4JM </div>
     </div>

      <div className="" onClick={() => $('.MobileNavbarDropdown').fadeToggle()}>
        <FaAlignLeft className='memu-icon mr-4 text-2xl'/>
      </div>
    </nav>
    <div className='MobileNavbarDropdown'>
    <ul className="">
      <li>
        <NavLink to={'/'} onClick={closeMenu} className="" aria-current="page">Home</NavLink>
      </li>
      <li>
        <NavLink to={'/about'} onClick={closeMenu} className="">About</NavLink>
      </li>
      <li>
        <NavLink to={'/services'} onClick={closeMenu} className="">Services</NavLink>
      </li>
      <li>
        <NavLink to={'/contact'} onClick={closeMenu} className="">Contact</NavLink>
      </li>
    </ul>

    <NavLink to={'/register'} onClick={closeMenu} type="button" className="btn">Join Us</NavLink>
    </div>
    </div>
  )
}

export default MobileNavbar
