import React from 'react'
import './NurseLanding.css';

function NurseLanding() {
  return (
    <div className='NurseLandingSection'>
    <div className='NurseLanding'>
        <div className="wrapper">
            <div className="anthem">
                <h1 className='text-3xl font-bold py-4'>Our Party Anthem</h1>
                <ul>
                    <li>Arise, Arise for Ghana</li>
                    <li>Ye Patriots of the Land</li>
                    <li>Towards secure future</li>
                    <li>From victory unto victory</li>
                    <li>The NDC shall lead</li>
                    <li>A stable democracy, Ghana</li>
                    <li>Our strength in unity</li>
                    <br />
                    <li>The NDC shall lead</li>
                    <li>A stable democracy, Ghana</li>
                    <li>Our strength in unity</li>
                </ul>
                {/* <h1 className="title">Lorem ipsum dolor sit amet Lorem, ipsum dolor.</h1>
                <p className="text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugit facere ut, soluta ex provident ab.</p>
                <button className="btn">Join Us Now</button> */}
            </div>
            <div className="image-section">
               
            </div>
        </div>
       
    </div>
    <div className="secondSection">
    <img src="/images/dev/NDC-flag-removebg-preview.png" alt="" />
    </div>
    </div>
  )
}

export default NurseLanding