import React, { useState } from 'react'
import axios from 'axios'
import Loader from '../Components/Loader'
import MessageResponse from '../Components/MessageResponse'
import { ToastContainer, toast } from 'react-toastify'

function Register() {
  const [formData, setFormData] = useState({})
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [data, setData] = useState(null)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  
  const handleRegister = async (e) => {
    e.preventDefault()
    console.log(formData)
    console.log(acceptTerms)

    
    try {
      setLoading(true)
      window.scrollTo(0, 0);
      // toast.success('Thank you for joining us. We will give you feeback shortly')
      const response = await axios.post('/dev/members', formData, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      
      if(response.status === 201){
      setLoading(false)
      // setData(response.data.Item)
      toast.success('Thank you for joining us. We will give you feeback shortly')
      // setMessage('Thank you for joining us. We will give you feeback shortly')
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      console.log('response', response)
    }
    // response.data.Item.Message === "SUCCESS" && 
      
    } catch (error) {
      console.log('Error', error)
    }
  }
  return (
    <>
    <div className='ResgisterPage' style={{ background: 'url(/images/dev/rtsushu.jpeg) no-repeat center center/cover' }}>
     {loading &&  <Loader/>}
     {message && <MessageResponse name={data.name} message={message}/>}
      <div className="overlay">
        <div className="items" >
          <h1 className="heading text-3xl  text-center " style={{ color: 'var(--secondaryColor)' }}>Register to join us</h1>
          <form className="py-4 px-4">

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="mb-6">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Fullname <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // placeholder="derrick@tiqniat.com"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email <span className='required'>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="example@gmail.com"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="mb-6">
                <label
                  for="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number <span className='required'>*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  for="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Cadre of Staff <span className='required'>*</span>
                </label>
                <select name='cadre_of_staff' required onChange={handleChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                  <option>Select</option>
                  <option value={'Medical Doctor'}>Medical Doctor</option>
                  <option value={'Nurse/Midwife'}>Nurse/Midwife</option>
                  <option value={'Physician Assistant'}>Physician Assistant</option>
                  <option value={'Pharmaceutical Service'}>Pharmaceutical Service</option>
                  <option value={'Administration Staff'}>Administration Staff</option>
                  <option value={'Supply Chanin'}>Supply Chanin</option>
                  <option value={'Account Staff'}>Account Staff</option>
                  <option value={'Public Health'}>Public Health</option>
                  <option value={'Other'}>Other</option>
                </select>
              </div>

            </div>
            <div className="grid md:grid-cols-2 md:gap-6">

              <div className="mb-6">
                <label
                  htmlFor="dob"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date of Birth <span className='required'>*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="regionOfBirth"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Region of Birth <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  id="regionOfBirth"
                  name="regionOfBirth"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                  required
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">

              <div className="mb-6">
                <label
                  htmlFor="current_region_of_residence"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Current Region of Residence / Work <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  id="current_region_of_residence"
                  name="current_region_of_residence"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // placeholder="derrick@tiqniat.com"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="current_town_of_residence"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Current Town of Residence / Work
                </label>
                <input
                  type="text"
                  id="current_town_of_residence"
                  name="current_town_of_residence"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="mb-6">
                <label
                  htmlFor="contituencyOfBirth"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Constituency of Birth <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  id="contituencyOfBirth"
                  name="contituencyOfBirth"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="current_position_in_the_party"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Current Position In The Party
                </label>
                <input
                  type="text"
                  id="current_position_in_the_party"
                  name="current_position_in_the_party"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                  onChange={handleChange}
                />
              </div>

            </div>
            <div className="mb-6">
              <label
                htmlFor="current_town_of_residence"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Can You Travel Outside your current town / constituency for health programs on the behave of the party?
              </label>
              <input
                type="radio"
                value={'Yes'}
                id='TravelYes'
                name='travel_outside'
                onChange={handleChange}
              /><label htmlFor='TravelYes'> Yes</label> <br />
              <input
                type="radio"
                value={'No'}
                name='travel_outside'
                id='TravelNes'
                // placeholder="derrick@tiqniat.com"

                onChange={handleChange}
              /> <label htmlFor='TravelNo'> No</label>
            </div>

            <div className="mb-6">
              <label
                htmlFor="current_town_of_residence"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Can This Group Help The 2024 Presidential And Paliamentary Campaign Of The Party?
              </label>
              <input
                type="radio"
                value={'Yes'}
                id='PresidentialYes'
                name='help_group_for_presidential_and_paliamentary'

                onChange={handleChange}
              /><label htmlFor='PresidentialYes'> Yes</label> <br />
              <input
                type="radio"
                value={'No'}
                name='help_group_for_presidential_and_paliamentary'
                id='PresidentialNo'
                // placeholder="derrick@tiqniat.com"

                onChange={handleChange}
              /> <label htmlFor='PresidentialNo'> No</label>
            </div>


            <div className="mb-6">
              <label
                htmlFor="embark_activities"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Which Activities Do You Want The Group To Embark On To Help The Party
              </label>
              <input
                type="text"
                id="embark_activities"
                name="embark_activities"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

                onChange={handleChange}
              />
            </div>






            <div className="flex items-start mb-6">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  required
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  checked={acceptTerms}
                />
              </div>
              <label
                for="remember"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Accept terms and conditions
              </label>
            </div>
            <div className="btnCover">
              <button
                type="submit"
                className="rgisterBtn"
                onClick={handleRegister}
              >
                Join Us Now
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
      <ToastContainer autoClose={3000}/>
      </>
  )
}

export default Register