import React, { useEffect } from "react";
import { FaBuffer } from "react-icons/fa";
import {BiPlusMedical} from 'react-icons/bi'
import $ from 'jquery'
import './About.css'


function About() {
  useEffect(() => {
    $('.VolunteerismContent').slideUp()
    $('.CommitmentContent').slideUp()
    $('.SelflessnessContent').slideUp()
  },[])

  const handleVolunteerismClick = () => {
    $('.VolunteerismContent').slideToggle()
  }
  const handleCommitmentClick = () => {
    $('.CommitmentContent').slideToggle()
  }
  const handleSelflessnessClick = () => {
    $('.SelflessnessContent').slideToggle()
  }
  return (
    <div className="About">
      <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
        <div className="overlay">
          <h1 className="text-white font-bold md:text-3xl sm:text-sm">We are NDC Health Workers</h1>
        </div>
      </div>
      <div className="">
        <div className="missionArea">
          <div className="containerMain">
            <div className="section1">
              <div>
                <h1 className="title text-xl font-bold text-green-500">Our Mission</h1>
                <p className="mission_text text-gray-500">
                  Mobilization of health workers and relevant resources towards securing victory for the NDC in the 2024 elections and beyond.
                </p>
              </div>
              <div className="py-4 pr-4">
                <h1 className="title text-xl font-bold text-green-500">Our Vision</h1>
                <p className="mission_text text-gray-500"></p>We envision a future where health and well-being are at the forefront of political agendas. Our health group is dedicated to advocating for evidence-based policies that prioritize prevention, promote healthy lifestyles, and ensure equal access to comprehensive healthcare services for all citizens. We strive to create a healthier nation that thrives on the principles of compassion, collaboration, and innovation."
              </div>
            </div>
            <div className="section2">
              <img src="/images/slides/surgery-688380_1920.jpg" alt="mission" />
            </div>
          </div>
        </div>

        <div className="InfoSection">
          <div className="containerMain">
            <div className="section1">
              <div className="title text-3xl font-bold text-green-500">About Health Workers 4 NDC</div>

              <p className="info text-gray-500">Health workers for H.E. John Mahama (HW4JM) is a group of health professionals of the party convened under one umbrella to work assiduously towards securing victory for the party and our presidential candidate in elections, 2024.</p>
              <p className="info text-gray-500">The group was formed on the 21st of December 2022 to provide a common platform for all health workers who wishes to work towards the victory of the party. This comprises of Medical Doctors, Nurses of various Specialties, Laboratory scientists and Technicians, Pharmacists, Health information officers and other cadres in the health sector. Membership is opened to all health workers in  the country and currently, registered members cut across the length and breadth of the country.  All current members are volunteers whose sole interest is to help the party in the upcoming "must win" elections. </p>
              <p className="info text-gray-500">These group of professionals are to see to the achievement of this goal by garnering and mobilizing resources (medical supplies and consumables) from internal and external donors as well as well-wishers. These resources will be utilized in carrying out activities such as: health screenings, donations to needy societies (targeted at using such activities as campaign tools for the party). Also, individual professionals within this group shall regularly embark on grassroots level campaigning, conduct research and develop recommendations that will be of great help to the NDC.</p>
              {/* <p className="info text-gray-500"></p> */}
              <p className="info text-gray-500">
                These professionals also envisage to contribute in fine-tuning the health sector policies for political campaigns of the party through research in the area of health in ensuring manifestos meet the expectations of electorates especially the floating voters.
              </p>
            </div>
            <div className="section2">
              <div className="title text-3xl font-bold text-green-500"> Core Values</div>
              {/* <p className="short_desc text-sm">Our values</p> */}
              <div className="coreValues">
                <div className="VolunteerismContainer" onClick={handleVolunteerismClick}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}} className="value1 bg-black text-white rounded-lg text-sm flex py-2 px-2 items-center my-4 cursor-pointer">
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="gap-4 "> <div className="icon"> <FaBuffer color='rgb(4 120 87)' /></div>
                    <div className="value text-sm">Volunteerism</div></div>
                    <div className="icon"> <BiPlusMedical color='rgb(4 120 87)' /></div>
                  </div>
                  <div className="VolunteerismContent">
                    Members shall be willing to give their time and resources out voluntarily in the achievement of the vision of the group. This shall include rendering of services freely with no recourse to any immediate financial benefit but for the sole reason of using such as a tool of campaign in favor of the great NDC and its Presidential Candidate in the coming elections. Same shall be decentralized to every constituency to ensure a victory in the Presidential election is synergized by a clear majority victory in parliament in favor of the party.
                  </div>
                </div>
                <div onClick={handleCommitmentClick}>
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="value1 bg-green-700 text-white rounded-lg text-sm flex py-2 px-2 items-center my-4 cursor-pointer">
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="gap-4 "> <div className="icon"> <FaBuffer color='white' /></div>
                    <div className="value text-sm">Commitment</div></div>
                    <div className="icon"> <BiPlusMedical color='white' /></div>
                  </div>
               
                  <div className="CommitmentContent">
                    As a core value that is dear to members of this group, each member shall be dedicated to the course of the group. Individual members will commit themselves and resources towards ensuring the smooth running of the group with long term benefits of seeing to the landslide victory of H.E John Dramani Mahama and the NDC in the coming 2024 elections.
                  </div>
                </div>

                <div onClick={handleSelflessnessClick}>
                <div style={{display: 'flex', justifyContent: 'space-between'}} className="value1 bg-red-700 text-white rounded-lg text-sm flex py-2 px-2 items-center my-4 cursor-pointer">
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="gap-4 "> <div className="icon"> <FaBuffer color='white' /></div>
                    <div className="value text-sm">Selflessness</div></div>
                    <div className="icon"> <BiPlusMedical color='white' /></div>
                  </div>

                
                  <div className="SelflessnessContent">The group intends to carry out its activities through a portal of selflessness without which trait no organization can thrive. With selflessness, the agenda of the party shall be topmost and prioritized over any other interests any member shall harbor. Any other interest so far as this group and its membership is concerned shall be subjugated solely to the supreme interest of seeing to the victory of the party in the 2024 elections. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ObjectiveSection">
          <h1 className="text-4xl text-center font-bold py-4">Our Objective</h1>
          <div className="containerMain">
            <div className="imageSec">
              <img src="/images/slides/surgery-688380_1920.jpg" alt="mission" />
            </div>
            <div className="objectives">
              <div className="items">
                <div className="icon">
                  <FaBuffer color='white' size={'20px'} />
                  <p>Embark on research-based Health screenings targeted at consolidating the votes of the party in all constituencies.</p>
                </div>
                <div className="icon">
                  <FaBuffer color='white' size={'20px'} />
                  <p>Conduct research for the party on matters of health</p>
                </div>
                <div className="icon">
                  <FaBuffer color='white' size={'20px'} />
                  <p>Receive and donate medical consumables and equipment on behalf of the party to various health facilities and communities wherever needed.</p>
                </div>
                <div className="icon">
                  <FaBuffer color='white' size={'20px'} />
                  <p>To organize strategic grassroots’ level campaigns for the Party</p>
                </div>
                <div className="icon">
                  <FaBuffer color='white' size={'20px'} />
                  <p>Contribute to the health sector policies and programs of the party and its manifesto. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
