import React, { useState } from 'react'
import axios from 'axios'
import Loader from '../Components/Loader'
import { ToastContainer, toast } from 'react-toastify'

import { FaFacebook, FaLinkedin, FaTelegram, FaTwitter } from 'react-icons/fa'
import { NavLink, useNavigate } from 'react-router-dom'


function Contact() {
  //  const [formData, setFormData] = useState({email: '', subject: '', phone_number: '', message: ''})
   const [formData, setFormData] = React.useState('')
   const [loading, setLoading] = useState(false)
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  };
  const navigate = useNavigate()
  const handleSendMessage = async (e) => {
    e.preventDefault() 
    try {
      setLoading(true)
      window.scrollTo(0, 0);
      // toast.success('Thank you for joining us. We will give you feeback shortly')
      // const response = await axios.post('https://lk1zztjeae.execute-api.us-east-2.amazonaws.com/dev/contact', formData, {
      const response = await axios.post('https://api.upnmg.com/api/v1/website/contact/healthworkers4jm', formData, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      
      if(response.status === 201){
      setLoading(false)
      // setData(response.data.Item)
      toast.success('Thank you for contacting us. We will give you feedback')
     
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      console.log('response', response)
    }
    // response.data.Item.Message === "SUCCESS" && 
      
    } catch (error) {
      console.log('Error', error)
    }
  }
  // const handleChange = (e) => {

  // }
  return (
    <div className='Contact'>
        {loading &&  <Loader/>}
      <div className="">
        <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
          <div className="overlay">
            <h1 className='text-white font-bold md:text-3xl sm:text-sm'>Contact Us Find Us</h1>
          </div>
        </div>
        <section className='contactSection'>
          <div className="contactForm">
            <form className="py-4 px-4">
              <div className="mb-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="your name here"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="example@gmail.com"
                  required
                  onChange={handleChange}
                />
              </div>
           

              <div className="mb-2">
                <label
                  htmlFor="subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Message
                </label>
                <textarea name='message' id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your message here..."></textarea>
              </div>



              {/* <div className="mb-6">
          <label
            for="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            onChange={handleChange}
          />
        </div> */}

              <div className=" items-start mb-2">
                <label
                  for="remember"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  We will reply you through the provided email address
                </label>
              </div>
              <button
                type="submit"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                onClick={handleSendMessage}
              >
                Send Us Message
              </button>
            </form>
          </div>
          <div className="contactInfo">
            <h1 className='title text-3xl font-bold text-green-500 mb-4'>Contact Info</h1>
            <div className="contactItem my-4">
              <div className="name  font-bold">Address</div>
              <div className="value text-gray-500">House No. RA48 , Zebilla, Upper East Region, Ghana </div>
            </div>
            <div className="contactItem my-4">
              <div className="name font-bold">Email</div>
              <div className="value text-gray-500">healthworkersndc@gmail.com<br /> healthworkersndc@hotmail.com </div>
            </div>
            <div className="contactItem my-4">
              <div className="name font-bold">Call Us</div>
              <div className="value text-gray-500">0203475757 </div>
            </div>
            <div className="contactItem my-4">
              <div className="name font-bold">Follow Us</div>
              <div className="value socialMediaIcons flex gap-4 my-4">
                <NavLink  to={'https://www.facebook.com/HW4JM'} target='_blank'>
                  <FaFacebook className='' size={'35px'} color='rgb(0, 81, 255)' />
                </NavLink>
                <NavLink to={'https://twitter.com/HW4JM'} target='_blank'>
                  <FaTwitter size={'35px'} color='rgb(0, 186, 219)' />
                </NavLink>
                <NavLink to={'https://t.me/HW4JM'} target='_blank'>
                  <FaTelegram size={'35px'} color='rgb(3, 87, 121)' />
                </NavLink>
              </div>
            </div>
          </div>
        </section>

        <section className='mapSection'>

        </section>
      </div>
      <ToastContainer autoClose={3000}/>
    </div>
    
  )
}

export default Contact