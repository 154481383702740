import React from 'react'
import {FaBuffer} from 'react-icons/fa'
import './Services.css';
import { NavLink, useNavigate } from 'react-router-dom';
function Services() {
  const navigate = useNavigate()


  return (
    <div className='Services'>
      <div className="container ">
        <h1 className='text-center text-green-500 px-2 py-6 pt-6 font-bold md:text-3xl sm:text-sm pb-6'>Our Services We Provide</h1>
        <div className="service_wrapper ">
          <div className="service  border-solid border-2 border-emerald-100 rounded-md">
            <div className="imgCover" style={{background: 'url(/images/downloads/members2.jpg) no-repeat center center/cover'}}>
              {/* <img src="" alt="" /> */}
            </div>
           
            <h1 className='title font-bold py-2'>Health screening </h1>
            
            <p className="title text-gray-600" >Health screenings shall be carried out by group in a strategic and timely manner aimed at consolidating our numbers among party members and making meaningful gains in popular votes.</p>
            <div className="btnCover">
              <NavLink className={'btn'} to={'/services/health-screening'}>Read More</NavLink>
            </div>
          </div>
          <div  className="service  border-solid border-2 border-emerald-100 rounded-md">
            <div className="imgCover" style={{background: 'url(/images/downloads/members2.jpg) no-repeat center center/cover'}}>
              {/* <img src="" alt="" /> */}
            </div>
            {/* <div className="icon">
              <FaBuffer color='rgb(4 120 87)'/>
            </div> */}
            <h1 className='title font-bold py-2'>Health Education </h1>
            {/* <div className="title py-2 font-bold text-md ">Service title here</div> */}
            <p className="title text-gray-600" >Another service group shall be rendering to prospective electorates is health education. Group will take it upon themselves to educate individuals on how to care for themselves after</p>
            <div className="btnCover">
              <NavLink className={'btn'} to={'/services/health-education'}>Read More</NavLink>
            </div>
          </div>
          <div className="service border-solid border-2 border-emerald-100 rounded-md">
            <div className="imgCover" style={{background: 'url(/images/downloads/members2.jpg) no-repeat center center/cover'}}>
              {/* <img src="" alt="" /> */}
            </div>
            {/* <div className="icon">
              <FaBuffer color='rgb(4 120 87)'/>
            </div> */}
            <h1 className='title font-bold py-2'>Donation of medical consumables and equipment </h1>
          
            <p className="title text-gray-600" >We also engage in occasional and need based strategic donation of medical consumables and equipment to facilities in need. This will be done by group’s efforts of... </p>
            <div className="btnCover">
              <NavLink className={'btn'} to={'/services/medical-donation'}>Read More</NavLink>
            </div>
          </div>
          <div   className="service  border-solid border-2 border-emerald-100 rounded-md">
            <div className="imgCover" style={{background: 'url(/images/downloads/members2.jpg) no-repeat center center/cover'}}>
              {/* <img src="" alt="" /> */}
            </div>
            {/* <div className="icon">
              <FaBuffer color='rgb(4 120 87)'/>
            </div> */}
            <h1 className='title font-bold py-2'>Political education on the party’s contribution to the health sector </h1>
         
            <p className="title text-gray-600" >group will also help in marketing party and its manifesto especially key areas touching on health care and delivery in the run up to the 2024 elections.</p>

            <div className="btnCover">
              <NavLink className={'btn'} to={'/services/political-education'}>Read More</NavLink>
            </div>
          </div>
        
          
        </div>
      </div>
    </div>
  )
}

export default Services