import React from 'react'
import { useNavigate } from 'react-router-dom'
import './serviceDetailed.css'

function PoliticalEducation() {
    const navigate = useNavigate()
  return (
    <div className='PoliticalEducation'>
          <div className="smallBanner" style={{ background: 'url(/images/slides/surgery-688380_1920.jpg) no-repeat center center/cover' }}>
        <div className="overlay">
          <h1 className="text-white font-bold md:text-3xl sm:text-sm">Political education on the party’s contribution to the health sector</h1>
        </div>
      </div>
         
        <div className="serviceBody">
            <div className="overlay">
                <div className="container">
            <p>This group will also help in marketing party and its manifesto especially key areas touching on health care and delivery in the run up to the 2024 elections. This will be done by members either during screening or any suitable platform gotten to execute such activities.</p>
                <div className="backBtn" onClick={() =>navigate('/services')}>Go Back</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PoliticalEducation
