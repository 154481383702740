
import './App.css';
import {Route, NavLink, Routes} from 'react-router-dom'
import HomePage from './Pages/HomePage';
import Navbar from './Components/Navbar';
import Contact from './Pages/Contact';
import Services from './Components/Services';
import About from './Pages/About';
import NotFound from './Pages/NotFound';
import Register from './Pages/Register';
import Footer from './Components/Footer';
import MobileNavbar from './Components/MobileNavbar';
import { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import HealthScreening from './Pages/servicesDetailed/HealthScreening';
import HealthEducation from './Pages/servicesDetailed/HealthEducation';
import PoliticalEducation from './Pages/servicesDetailed/PoliticalEduction';
import DonationOfMedicalEquip from './Pages/servicesDetailed/DonationOfMedicalEquip';
import ScrollToTop from './Components/ScrollToTop';
import "react-toastify/dist/ReactToastify.css";

function App() {
  const device = () => {
    return window.innerWidth < 1080;
  };
  
  const [isMobile, setIsMobile] = useState(device());
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(device());
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  
  console.log('mob', isMobile)
  return (
    <div className="">
      {isMobile ? <MobileNavbar/> :  <Navbar/>}
      {isMobile ? <div className='pt-12'></div> :  ''}

      
      
      {/* <Navbar/> */}
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/services/health-screening' element={<HealthScreening/>}/>
        <Route path='/services/health-education' element={<HealthEducation/>}/>
        <Route path='/services/political-education' element={<PoliticalEducation/>}/>
        <Route path='/services/medical-donation' element={<DonationOfMedicalEquip/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/*' element={<NotFound/>}/>

      </Routes>
      <Footer/>
      <ScrollToTop/>
    </div>
  );
}

export default App;
