import React from 'react'
// import Carousel from '../Components/Carousel'
import Services from '../Components/Services'
import CEOMessage from '../Components/CEOMessage'
import NurseLanding from '../Components/NurseLanding'
import CarouselComponet from '../Components/Carousel'
import Gallery from './Gallery'
import HealthWorkersSection from './HealthWorkersSection'

function HomePage() {
  return (
    <div>
        <CarouselComponet/>
        
        <HealthWorkersSection/>
        {/* <Services/> */}
        <NurseLanding/>
        <CEOMessage/>
        <Gallery/>
       
    </div>
  )
}

export default HomePage